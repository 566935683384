<template>
    <pagination title="Settings" :entities="entity" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :checkSize="false" :withEditIcon="true" :editing="editing" @editClicked="$emit('editClicked')" @saveClicked="$emit('saveClicked')" header-colour-class="bg-transparent">
        <template v-slot:items>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Approval Threshold
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="approval_threshold"
                        :action="editing? action: null"
                        fieldId="approval_threshold"
                        :showLabel="false"
                        v-model="updateActionData['approval_threshold']">
                    </property-or-field>
                    <div v-show="errors.has('approval_threshold')" class="text-red-600">{{ errors.first('approval_threshold') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Default Currency
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="default_currency"
                        :action="editing ? action: null"
                        fieldId="default_currency"
                        :showLabel="false"
                        v-model="updateActionData['default_currency']">
                    </property-or-field>
                    <div v-show="errors.has('default_currency')" class="text-red-600">{{ errors.first('default_currency') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr v-if="entity.properties.get('warehouse_address') !== null || action.fields.filter(field => field.name === 'warehouse_address').first()">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Warehouse Address
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                                       :properties="entity.properties"
                                       property-key="warehouse_address"
                                       :action="editing ? action: null"
                                       fieldId="warehouse_address"
                                       :showLabel="false"
                                       v-model="updateActionData['warehouse_address']">
                    </property-or-field>
                    <div v-show="errors.has('warehouse_address')" class="text-red-600">{{ errors.first('warehouse_address') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr v-if="entity.properties.get('cms_quote_required') !== null">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    CMS Quote Required
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="cms_quote_required"
                        :action="editing ? action: null"
                        fieldId="cms_quote_required"
                        :showLabel="false"
                        v-model="updateActionData['cms_quote_required']">
                    </property-or-field>
                    <div v-show="errors.has('cms_quote_required')" class="text-red-600">{{ errors.first('cms_quote_required') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";

export default {
    inject: ["$validator"],
  name: 'ProcurementSettingsPagination',
    components: {PropertyOrField, Loading, Pagination},
  props: {
    entity: {},
      collapsable:{
        type: Boolean,
        default: false,
      },
      open:{
        type: Boolean,
        default: true
      },
      action:{
        type: Object,
          default: null
      },
    editing:{
        type: Boolean,
        default: false
    },
      updateActionData:{
        type: Object
      }
  },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
